.faqHeader{
  text-align: left;
  margin-bottom: .2rem;
}

.faqBody{
  font-size: 0.9rem;
}

.faqData{
  margin-bottom: 3rem;
}