.rosterHeader {
  text-align: left;
  margin-bottom: .2rem;
}

.rosterBody {
  font-size: 13px;
}

.rosterData {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 3rem;
}