.iskIcon {
  width: 10rem;
  height: 10rem;
  border-radius: 2rem;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.titleObombercare {
  color: var(--header-color);
}

.InsideContainer {
  margin: 5%
}

li,p {
  text-align: left;
}
