.name{
  text-align: center;
  margin-bottom: .2rem;
}

.amount{
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
/*    color: hsl(0,100%,32%);
*/

.donation{
  margin: 1em;
  display: inline-block;
  width: 280px;
  height: 200px;
  background: rgba(163, 0, 0, 0.4);
  border-bottom:1px solid #ccc;
  border-left:1px solid #ccc;
  border-right:1px solid #ccc;
  border-top:none;
  box-shadow: 0 0.15em 0.35em 0 rgba(0,0,0,0.135);
  overflow: hidden;
}

.donation .body {
  padding: 5px;
  height: 152px;/* 200 - 20 - 4*7px */
}

.characterPortrait {
  width: 152px;
  height: 152px;
  float: left;
}

.donationTitle{
  padding: 7px;
  background: hsl(0,100%,32%);
  color:white;
  height: 20px;
  font-weight: 700;
}

.donatorDetail {
  float: left;
  width: 80%;
  height: 100%;
}

.donatorImgContainer img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.donatorDetailsContainer{

}

.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0px;
}
