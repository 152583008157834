.infoBox {
  position: absolute;
  width: 100%;
  height:70px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 0.15em 0.35em 0 rgba(0,0,0,0.135);
  border-bottom: 1px solid var(--background-color);
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cta-color);
  color: var(--bright-font-color);
}

.infoBoxText strong {
  padding-left: 5px;
  padding-right: 5px;
}

.infoBoxText {
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;

}

.infoBox .infoBoxText img {
  width: 25px;
  height: 25px;
}

.infoBoxText a {
  font-weight: bold;
  color: var(--bright-font-color);
}