.homepageContainer {
  padding-top: 100px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; 
}

.homepageButtonContainer h1 {
  font-weight: 400;
}

.homepageButtonContainer {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
}

.bottomSpacing {
  margin-bottom: 1rem;
}

h1, h2 {
  font-weight: 400;
}

.homepageTitle {
  text-align: center;
}

.inline {
  display: inline-block;
}

.table {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.tableList h3 {
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--header-color);
  letter-spacing: -0.042em;
  font-weight: 400;
}

.tableContents{
  text-align: left;
  margin-top: 5px;
}
.alignLeft{
  float: left;
  max-width: 50%;
  margin-left: 5%;
}

.alignRight{
  float: right;
  max-width: 40%;
  margin-right: 5%;
}

.fullSize{
  margin-top: 1rem;
  width:100%;
}

.tableList{
  list-style: none;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

ul.tableList li {
  position:relative;
  margin-bottom: 0;
  padding-bottom: 1em;
}

ul.tableList li:after {
  background-color: var(--background-color);
  color:var(--header-color);
  font-family: 'Font Awesome 6 Free';
  content: "\f192";
  position: absolute;
  left: -23px;
  top: 0.4em;
}

ul.tableList li:before {
  content:"";
  position: absolute;
  left: -16px;
  border-left: 1px solid var(--font-color);
  height: 100%;
  width: 1px;
  top: 0.4em;
}

ul.tableList li:first-child:before {
  top: 0.4em;
}

ul.tableList li:last-child:before {
  height: 5px;
}

.howToJoinOuter {
  background-color: var(--highlight-color);
}

.howToJoinInner {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.howToJoinInner td {
  vertical-align: top;
}

.howToJoinContents {
  padding-right: 15px;
  padding-left: 15px;
  text-align: justify;
  text-justify: inter-word;
}

.doctrineTitle{
  padding: 0;
}

#grid{
  display:grid;
  height:200px;
  width:67vw;
  grid-template-columns: auto auto auto auto auto;
  /*background:red;*/
}
#wrapper{
  display: flex;
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
  padding-top:50px;
  padding-bottom:100px;
}

.homepageButtonContainer-button .name {
  color: var(--background-color);
  font-weight: bold;
}

#wrapper a:hover .homepageButtonContainer-button {
  background-color: var(--cta-hover-color);
}

#wrapper a{
  text-decoration: none !important;
}

.homepageButtonContainer-button {
  display:flex;
  height:250;
  width:15vw;
  background: var(--cta-color);
  border-radius:5px;
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
  opacity:0.9;
  box-shadow: 5px 5px 15px 3px var(--shadow-color);
}

#care{
  background:url('./../images/damage_control.png');
}

#fittings{
  background:url('./../images/nemesis.png');
}

#discord{
  background:url('./../images/logo_discord.png');
}

#teamspeak {
  background: url("./../images/logo_teamspeak.png");
}

.logo-service{
  margin-top: 40px;
  width:90%;
  height:150px;
  padding:5px;

  background-position: center !important;
  border-radius:10px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}