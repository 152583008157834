.slick-prev:before, .slick-next:before {
    color: var(--header-color);
}

.slick-dots li button:before {
  color: var(--header-color);
}

.slick-dots li.slick-active button:before{
  color: var(--header-color);
}

.NewBroVideos{
  margin-bottom: 5rem;
}

@media only screen and (max-width: 767px) {
    .gallery {
        margin: 0 10%;
    }
}