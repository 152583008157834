.terminologyHeader{
  text-align: left;
  margin-bottom: .2rem;
}

.terminologyData{
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 3rem;
}

.subList{
  margin-left: 10px;
}