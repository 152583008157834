.navigationBar{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.navigationBar ul {
  list-style: none;
  padding: 0;
  margin-top: 2.5rem;
  float: right;
}

.navigationBar ul li {
  display: inline;
  padding: 10px 10px;
}

.navigationBar ul li a {
  text-decoration: none;
  color: var(--font-color);
}

.navigationBar ul li a:hover {
  color: var(--header-color);
}

.navigationBar h2 a {
  color: var(--header-color);
  text-decoration: none;
}

.bblogo{
  height: 100px;
  width: 100px;
  display: inline;
}

.bbName{
  margin-left: 2rem;
  vertical-align: 2.5rem;
  display: inline;
}

.logoName{
  float: left;
}