.App {
  text-align: center;
  font-size: 16px;
  color: var(--font-color);
}

.App-header {
  background-color: var(--background-color);
  height: 100px;
  box-shadow: 0 0.15em 0.35em 0 var(--shadow-color);
  border-bottom:1px solid #ccc;  
}

.contentContainer {
  padding-top: 100px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;  
}